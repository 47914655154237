export const strapiGetI18nSortKey = (locale: string = '', value: string) => {
  switch (locale) {
    case 'en-US':
      return `${value}_en`
    case 'al-AL':
      return `${value}_al`
    default:
      return `${value}_en`
  }
}
